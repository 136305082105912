import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { Freighter } from '@/components/visuals';

export const HomePageFooter: React.FC = () => {
  const { t } = useTranslation('home');

  return (
    <footer>
      <div className="container">
        <div className="py-8">
          <div className="flex justify-between items-center py-8 border-t border-bg-500">
            <a target="_blank" href="https://freighter.studio" rel="noreferrer">
              <Freighter className="fill-brand-default-500 text-brand-default-500 w-12 h-auto" />
            </a>
            <nav>
              <p className="mb-5 text-base font-semibold text-text-500">
                {t('legal.title')}
              </p>
              <Link
                className="block mt-3 text-sm text-text-500 hover:underline"
                target="_blank"
                href="/legal/privacy"
              >
                {t('legal.privacy')}
              </Link>
              <Link
                className="block mt-3 text-sm text-text-500 hover:underline"
                target="_blank"
                href="/legal/terms"
              >
                {t('legal.terms')}
              </Link>
              <Link
                className="block mt-3 text-sm text-text-500 hover:underline"
                target="_blank"
                href="/legal/cookie"
              >
                {t('legal.cookie')}
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};
