import React from 'react';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { Logo } from '@/components/visuals';

type Props = {
};

export const HomePageHeader: React.FC<Props> = () => {
  const { t } = useTranslation('home');

  return (
    <header>
      <div className="bg-brand-default-500 py-12 transition-all md:py-13">
        <div className="container">
          <div className="grid grid-cols-1 justify-items-center">
            <Logo className="block w-13 h-auto text-text-inverted" />
            <h1 className="hidden">{t('name')}</h1>
            <p className="mt-7 text-text-inverted text-center text-3xl md:text-4xl">
              <Trans
                i18nKey="home:title"
                components={{ strong: <strong /> }}
              />
            </p>
          </div>
        </div>
      </div>
      <svg className="block w-full fill-brand-default-500 text-brand-default-500 -mt-one h-9 md:h-10" viewBox="0 0 100 16" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
        <path d="M100 0.205261C100 8.60291 77.6142 15.4106 50 15.4106C22.3858 15.4106 0 8.60291 0 0.205261C0 0.205261 22.3858 0.20532 50 0.20532C77.6142 0.20532 100 0.205261 100 0.205261Z" />
      </svg>
    </header>
  );
};
