import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { PlanBox } from '../../../generic';
import { FIGMOTION_PLUGIN_LINK, PlanBoxes } from '@/constants';

export const HomePageContent: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation('home');

  const handleFree = () => {
    window.open(FIGMOTION_PLUGIN_LINK, '_blank');
  };

  const handleBuyPlan = () => {
    router.push('/account/select-plan');
  };

  return (
    <main>
      <div className="container">
        <div className="grid grid-cols-1 justify-items-center py-12 md:py-13">
          <div>
            <p className="mx-auto max-w-2xl text-center text-xl text-text-default">
              {t('pricing.title')}
            </p>
          </div>
          <div className="mt-6 w-full grid grid-flow-row auto-rows-max gap-5 grid-cols-1 md:grid-cols-3">
            <PlanBox
              {...PlanBoxes.FREE}
              action={{
                label: 'Get the plugin',
                onClick: handleFree,
              }}
            />
            <PlanBox
              {...PlanBoxes.STANDARD}
              action={{
                label: 'Get the plugin',
                onClick: handleBuyPlan,
              }}
            />
            <PlanBox
              {...PlanBoxes.PROFESSIONAL}
              action={{
                label: 'Get the plugin',
                onClick: handleBuyPlan,
              }}
            />
          </div>
        </div>
      </div>
    </main>
  );
};
