import { NodeOrigins } from '@figmotion/figmotion-constants';

export const APP_FRAMERATE = 60;
export const APP_PLAY_FRAMERATE = 30;
export const APP_TIMELINE_EXTENSION_STEP = 5000;
export const DEFAULT_ANCHORPOINT = NodeOrigins.MIDDLECENTER;

export const FIGMOTION_RENDERER_VIDEO_ENDPOINT =
  'https://videos.figmotion.freighter.studio/videos';
export const FIGMOTION_PLUGIN_LINK =
  'https://www.figma.com/community/plugin/733025261168520714/Figmotion';
