import { SubscriptionPlanType } from '@figmotion/figmotion-constants';

export const PlanBoxes = {
  [SubscriptionPlanType.Free]: {
    title: 'Free',
    features: [
      'All animation features',
      'Export to code',
      'Render as WEBM/MP4/GIF-720p and WEBP',
    ],
  },
  [SubscriptionPlanType.Standard]: {
    title: 'Standard',
    pricing: { monthly: 10, yearly: 96 },
    features: [
      'Everything in Free',
      'Versioning (7 days)',
      'Priority rendering (level 1)',
      'Basic variables support',
      'Flexible anchor points',
    ],
  },
  [SubscriptionPlanType.Professional]: {
    title: 'Professional',
    pricing: { monthly: 25, yearly: 240 },
    features: [
      'Everything in Standard',
      'Versioning (30 days)',
      'Priority support',
      'Priority rendering (level 2)',
      'Increased GIF quality (1440p)',
      'Premium properties',
    ],
  },
};
