export const Shortcuts = Object.freeze({
  RESET_ZOOM: 'ctrl+0, command+0',
  JUMP_TO_TIME: 'ctrl+j, command+j',
  UNDO: 'ctrl+z, command+z',
  REDO: 'ctrl+y, command+y, ctrl+shift+z, command+shift+z',
  SET_INPUT_POINT: 'i',
  SET_OUTPUT_POINT: 'o',
  RESET_INPUT_POINT: 'ctrl+k, command+k',
  RESET_OUTPUT_POINT: 'ctrl+m, command+m',
  REMOVE_INPUT_OUTPUT_POINTS: 'ctrl+l, command+l',
  SAVE: 'ctrl+s, command+s',
  QUICK_LAYER_SELECT: 'ctrl+p, command+p',
  TOGGLE_FIGMA_PANE: 'ctrl+alt+p, command+alt+p'
});
