import React from 'react';
import { HomePageContent } from '@/components/pages/home/HomePageContent';
import { HomePageFooter } from '@/components/pages/home/HomePageFooter';
import { HomePageHeader } from '@/components/pages/home/HomePageHeader';

type Props = {
};

export const HomePage: React.FC<Props> = () => (
  <>
    <HomePageHeader />
    <HomePageContent />
    <HomePageFooter />
  </>
);
